$c-primary: #FB8B24;
$c-secondary: #000000;
$c-tertiary: #F5F5F5;

$max-width: 1920px;
$min-width: 1400px;

$max-width-small: 1920px;
$min-width-small: 250px;

$mobile-breakpoint: 1200px;

@mixin mobile {
    @media only screen and (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin border {
    border: 5px solid $c-secondary;
    border-radius: 80px;
    // box-shadow: 5px 5px;
}

@mixin base-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 10%;
    max-width: $max-width;
    min-width: $min-width;
    box-sizing: border-box;
    transition: all 1s;

    @include desktop {
        opacity: 0.5;
        filter: blur(5px);

        &.Show {
            opacity: 1; 
            filter: none;
        }
    }
    
    .Body {

    }
    
    .Arrows {
        display: flex;
        position: absolute;
        left: 50%;
        translate: -50% 0;
        gap: 48px;
        bottom: 64px;
    }

    @include mobile() {
        width: 100vw;
        max-width: $max-width-small;
        min-width: $min-width-small;
        flex-direction: column;
        height: 100vh;
        padding: 10% 10%;
        .Body {
            flex-direction: column;
            min-width: 250px;
        }

        .Arrows {
            display: none;
        }
    }
}