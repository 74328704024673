@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=League+Spartan:wght@500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import 'styles/Styles.scss';

body {
  margin: 0;
  font-family: 'Gothic A1', sans-serif;

  @include desktop {
    cursor: url('./icons/cursor/icons8-cursor-48.png'), pointer;
  }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #FB8B24;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b1631a;
}