
.Blob {
    #visual {
        z-index: -1;
        animation: rotate 30s linear 0s infinite normal forwards;
    }
    svg {
        aspect-ratio: 3 / 2;
        // width: 900px;
        width: 100%;
        height: 100%;
    }
}

@keyframes rotate {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}