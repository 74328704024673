@import '../../styles/Styles.scss';

.About {
    overflow-x: hidden;
    @include base-page;
    .Body {
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 120px;

        .InfoHeading {
            z-index: 2;
            flex: 1;
            display: flex;
            justify-content: center;
        }

        .InfoDescription {
            z-index: 2;
            flex: 1;
        }

        .Blob {
            width: 500px;
            position: absolute;
            top: 12%;
            right: 15%;
        }

        &.Reverse {
            flex-direction: row-reverse;

            .Blob {
                width: 500px;
                position: absolute;
                top: 12%;
                left: 0%;
            }
        }
    }

    @include mobile {
        .Body {
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;
            gap: 80px;
            .TitleBody {
                padding: 0;
                max-width: 100%;
                
            }
    
            .Logo {
                height: 150px;
                // width: 300px;
            }

            .Blob {
                width: 200px;
                // height: 150px;
            }

            &.Reverse {
                flex-direction: column-reverse;
                .Blob {
                    width: 200px;
                    top: 12%;
                    left: 0%;
                }
            }
        }
    }
}
  