@import '../../animations/border.scss';
@import '../../animations/hover.scss';
@import '../../styles/Styles.scss';

.Arrow {
    z-index: 0;
    border-radius: 50%;
    padding: 8px;
    position: relative;
    border: 1px solid transparent;
    transition: border-bottom 1s, opacity 1s;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-spin($c-primary);
    opacity: 0.2;
    animation: float 4s infinite;
    // transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
    &.Down {
        rotate: 180deg;
       
    }
    &:hover {
        opacity: 1;

    }

    .ArrowIcon {
        z-index: 1;
    }

    .Background {
        position: absolute;
        aspect-ratio: 3 / 2;
        width: 250px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

}