@import '../../styles/Styles.scss';
.TitleBody {
    text-align: left;
    h1 {
        font-size: var(--font-xxlarge);
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 64px;
        line-height: var(--line-xxlarge);
        strong {
            font-weight: 900;
        }
    }

    p {
        font-weight: 300;
        letter-spacing: -0.5%;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }


 
}

@media only screen and (max-width: $mobile-breakpoint) {
    .TitleBody {

        h1 {
            margin-top: 32px;
        }
    
        p {

        }
    
    
     
    }
    
}