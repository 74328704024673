@import '../../animations/fill.scss';
@import '../../animations/border.scss';
@import '../MenuBar/Styles.scss';
@import '../../styles/Styles.scss';

.MenuBarItem {
	display: flex;
	justify-content: center;
	align-items: center;
    background-color: transparent;
    border-radius: 70px;
    padding: 24px;
	font-weight: bolder;
	font-size: var(--font-xsmall);

	color: rgba($color: $c-secondary, $alpha: 0.5);
	&.Active {
		// font-size: 1.7rem;
		color: rgba($color: $c-secondary, $alpha: 1);
	}

	@include mobile {
		border-radius: 0;
		font-size: medium;
		color: rgba($color: $c-secondary, $alpha: 0.3);
	}
}


// Hex Codes: #4169e1, #ff6b35, #eef4ed