@import '../../styles/Styles.scss';

.Home {
    @include base-page;
    .Body {
        gap: 32px;
        display: flex;
        justify-content: space-between;
        .TitleBody {
            padding: 32px;
            max-width: 40%;
        }

        .Logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @include mobile {
        .Body {
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;

            .TitleBody {
                // margin-top: 32px;
                padding: 0;
                max-width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
    
            .Logo {
                height: 230px;
            }
        }
    }
}
  


@keyframes fade-in {
    0% {opacity: 0; filter: blur(4px)}
    100% {opacity: 1; filter: none}
}