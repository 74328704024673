
$y-movement: 8px;

@keyframes float {
    0% {
         transform: translate(0px, calc(0 * $y-movement));
     }
  
     50% {
         transform: translate(0px,calc(-1 * $y-movement));
     }
  
}