@import './Styles.scss';
@import '../../styles/Styles.scss';
@import '../../animations/highlight.scss';

.MenuBar {
    z-index: 9;
    position: fixed;
    top: 32px;
    left: 50%;
    translate: -50% 0;
    display: inline-flex;
    @include border;
    background-color: #f5f5f5;
    overflow: hidden;
    max-width: 700px;
    width: 100%;


    @include mobile {
      width: 100vw;
      border: none;
      top: 0;
      box-shadow: 0 0;
      border-radius: 0;
      display: flex;
      justify-content: space-around;
      background: linear-gradient(to bottom, rgb(245, 245, 245), rgba(245, 245, 245, 0));
    }
}

.Indicator {
  @include desktop {
    @include highlight-indicator-x('.MenuBarItem', 3);
  }
}
