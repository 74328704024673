@import './styles/Styles.scss';

:root {
  --font-xxlarge: 8rem;
  --font-xlarge: 6rem;
  --font-large: 2.5rem;
  --font-medium: 2rem;
  --font-small: 2rem;
  --font-xsmall: 1.5rem;

  --line-xxlarge: 8rem;
  --line-xlarge: 8rem;
  --line-large: 6.5rem;
  --line-medium: 2rem;
  --line-small: 2.5rem;
  --line-xsmall: 2rem;
}


@media only screen and (max-width: $mobile-breakpoint) {

  :root {
    --font-xxlarge: 4rem;
    --font-xlarge: 3rem;
    --font-large: 1.25rem;
    --font-medium: 1rem;
    --font-small: 1.1rem;
    --font-xsmall: 1rem;

    --line-xxlarge: 4rem;
    --line-xlarge: 4rem;
    --line-large: 4rem;
    --line-medium: 1rem;
    --line-small: 1.25rem;
    --line-xsmall: 1.25rem;
  }
}


#root {
  background-color: #F5F5F5;
}

.App {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .Wave {
    position: absolute;
    bottom: -5px;
    left: 0;
    height: fit-content;
    z-index: 0;
    width: 100%;
  }

  > .Blob{
    position: absolute;
    bottom: 0;
    left: 0;
    height: fit-content;
    z-index: 0;
    width: 100%;
  }
}
