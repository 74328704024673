@import '../../styles/Styles.scss';

.InfoDescription {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:has(img) {
        gap: 48px;
    }

    .Container {
        display: flex;
        
        img {
            padding-right: 64px;
        }
    }

    .Text {
        h2, a {
            margin-top: 0;
            font-size: var(--font-large);
            font-weight: 800;
            margin: 0;
            line-height: var(--line-large);
            color: $c-secondary;
        }
    
        p {
            font-weight: 300;
            letter-spacing: -0.5%;
            font-size: var(--font-xsmall);
            line-height: var(--line-xsmall);
            margin: 0;
        }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        .Container {
            img {
                width: 80px;
                padding-right: 32px;
            }
        }
    }

    
}